.customtable  {

    --bs-table-bg:#f8f9fa;
    --bs-table-border-color:#d4caca;
    --bs-table-border-radius:1px;
    width:90%;
    margin:2%;
    text-align: center;
    align-content: center;
}

.customtable img{
    width: 20%;
    height: 20%;
}

.customtableheader th {
    color: rgb(188, 65, 4);
}


.dashboard_card{
    justify-content: center;
    margin-left: 5%;
}

.dashboard{
 background-color: DeepSkyBlue;
 padding: 1%;
 margin-top: 0.5%;
border-radius: 8px;
}

.dashboard h3{
    color: white;
    text-align: center;
}

.dashboardEmpty {
    text-align: center;
    font-size: medium;
    margin: 3%;
}

.dashboardEmpty a {
    color: #da3b13;
}

.dashboardEmpty a:hover{
    color: white;
}

