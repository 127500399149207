.celebrations-banner {
    display: block;
    margin: 30px auto;
    width: 82%;
}

.celebrations-indexSort {
    display: flex;
    margin: 0px 170px;
    justify-content: space-between;
    align-items: center;
}

.celebrations-sort {
    padding: 10px 20px;
    border-radius: 40px;
    border: 1px solid #888;
}

.celebrations-sort img {
    width: 30px;
}

.celebrations-indexSort p span {
    font-weight: 600
}

.celebrations-products {
    
    display: flex;
     flex-wrap: wrap; 
     
    
}

.celebrations_sidemenu{
    background-color: #eae8f2;
    border-radius: 0.5%;
    padding: 1%;
    box-shadow: 0px 0px 3px 0px ;
    display: flex;
    flex-direction: column;
    min-height: 79.8vh;

    
}

.celebrations_sidemenu a{
    color:white;
    font-weight: 500;
    
}

.sidemenu_link {
    background-color: #BDC3C7;
    border-radius: 4px;
    margin:2%;
    font-weight: 500;
    
}

.sidemenu_link_active{
    
    color:black !important;
    border-radius: 4px;
    
    background-color: #4cb2f6;
    margin:2%;
}

.celebrations_sidemenu a:hover{
    color:black;
    background-color: #aad7f8;
    border-radius: 4px;
    
}

.celebrations-loadmore {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 150px auto;
    width: 233px;
    height: 69px;
    border-radius: 75px;
    background: #ededed;
    color: #787878;
    font-size: 18px;
    font-weight: 500;
}

.celebrationdisplay-right {

    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    justify-content: center;
    width: 82%;
    margin: 30px auto;
    display: flex;
    flex-wrap: wrap;

}

.celebrationdisplay-right >button{
    background-color: #4cb2f6;
    border-color:#4cb2f6; 
}

.celebrationdisplay-right >button:hover{
    background-color: #aad7f8;
    border-color:  #aad7f8;
}

.side_menu {
    width:fit-content;
    background-color: #E3F2FD;
    padding-right: 5%;
    
}

.celebration_content{
    padding-top: 1%;
    
}
.note {
    font-size: 25px;
    color: #4311ce;
    text-wrap: wrap;
    text-align: center;
    margin-top: 5%;
    font-style: italic; 
}