.cartitems {
    margin: 100px 170px;
}

.cartitems hr {
    height: 3px;
    background: #2a2a2a;
    border: 0;
}

.cartitems-format-main {
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 75px;
    padding: 10px 0px;
    color: #454545;
    font-size: 18px;
    font-weight: 600;
}

.cartitems-format {
    font-size: 17px;
    font-weight: 500;
}

.carticon-product-icon {
    height: 100px;  
}

.cartitems-remove-icon {
    height: 40px;
    cursor: pointer;
}
 
.cartitems-quantity {
    width: 60px;
    height: 50px;
    border: 2px solid #ebebeb;
    background: white;

}

.cartitems-down {
    display: flex;
    margin: 100px 0px;
}

.cartitems-total {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 200px;
    gap: 40px;
}

.cartitems-total-items {
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;
}

.cartitems-total button {
    width: 260px;
    height: 60px;
    border: none;
    outline: none;
    background: #4cb2f6;
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.cartEmpty {
    text-align: center;
    font-size: medium;
    margin: 5%;
}

.cartEmpty a {
    color: #da3b13;
}

.cartEmpty a:hover{
    color: white;
}

.cart-item-toggle {

    display: flex;
    background-color: #f5f1f2;
   -ms-flex-align: center;
    border-radius: 8px;
    width: 100%;
    padding-left: 5px;
    color: #116466;
    font-weight: 600;

}

.cart-item-toggle>button {
   
    border-style: none;
    padding: 2px;
    
}

.cart-item-toggle-quantity {
    
    margin: 5px 0px;
    width:45%;
    text-align: center;
    
}