.popular {

    align-items: center;
    gap: 10px;

}

.popular h1 {
    color: #171717;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

.popular hr {

    height: 1.5px;
    border-radius: 10px;
    background: #be0202;

}

.popular-item {
    margin-top: 50px;
    display: flex;
    gap: 30px;
}