.descriptionbox {
    margin: 120px 170px;
}

.descriptionbox-navigator {
    display: flex;
}

.descriptionbox-navbox {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    width: 170px;
    height: 70px;
    border: 1px solid #d0d0d0; 
}

.descriptionbox-navbox.fade {
    background: #fbfbfb;
    color: #555;
}