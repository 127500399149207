.login-signup-container {
    width: 50%;
    height: auto;
    background: white;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 60px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    align-self: center;
    vertical-align: middle;

}

.login-signup-container button {
    cursor: pointer;
}

.login-signup-container button:active {
    cursor: pointer;
    background-color: #f3f3f3;
}

.login-signup-fields {

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.login-signup-label {

    outline: none;
    color: #1a015e;
    font-size: 18px;
    padding: 0.5%;
    margin-left: 2%;

}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.authButton {
    background-color:#4cb2f6 !important;
}

.authButton:hover {
    background-color:#aad7f8 !important;
    border-color:#aad7f8 !important;
  }