.nav-logo {
    display: flex;
    align-items: center;
    gap: 10px;

}

.nav-logo img {
    width: 90px;
    height: 90px;

}

.nav-logo p {
    color: white;
    font-size: 25x;
    font-weight: 500px;
}

.nav-search{
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav-menu {

    color: white !important;
    font-weight: 500px;
    font-size: large;

}

.nav-menu a {

    color: white ;
    font-weight: 600px;
    font-size: large;
    margin: 0px 2px;

}

.nav-menu a:hover {
    color: black !important;
    border-radius: 6px;
    
}

.nav-menu-active {
    color: black !important;
    background-color: #85C1E9 !important;
    font-weight: 500;
    border-radius: 6px;
    text-decoration: underline rgb(228, 156, 156) 2px dotted;
    padding-top: 5px;
}

.nav-menu-active a{
    color:black;
}


.nav-submenu {
    color: #b90d07 !important;
}

.nav-submenu a {
    
    text-decoration: none;
}

.nav-submenu a:hover {
    background-color: #f8e0db !important;
    
}

.nav-menu hr {
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background: red;
}

.nav-login {
    display: flex;
    align-items: center;
    gap: 20px;
}

.login_user_welcometext {
    color:black;
    padding-top:8%;
}

.nav-login-cart {
    display: flex;
    align-items: center;
    gap: 20px;
}

.nav-login-cart button {
    cursor: pointer;
}

.nav-login-cart button:active {
    background: #6c0202;
}

.nav-cart-count {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -30px;
    margin-left: -35px;
    border-radius: 11px;
    font-size: 14px;
    background: red;
    color: white;
}

.dropdown-menu {
    background-color: #E3F2FD;
}