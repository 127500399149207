.product-container{
    width: 70%;
    height: 96%;
    background: white;
    margin-top: 0.5%;
    margin-bottom: 0.5%;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 60px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    align-self: center;
}

.product-fields {

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.product-label {

    outline: none;
    color: #210868;
    font-size: 18px;
    padding: 0.5%;
    font-weight: 400;

}
       
.product-subcategories{
    margin-left: 4%;
}

.product-category-main {
    margin-bottom: 4%;
    margin-left: 2%;
}

.btn-primary{
    background-color: #4cb2f6;
    border-color:#4cb2f6; 
}

.btn-primary:hover{
    background-color:#aad7f8;
    border-color:#aad7f8;
}
