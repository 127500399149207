.item {
    margin: 1%;
    border-radius: 1rem;
    border: 1px solid rgb(224, 221, 221);
    padding: 0.5%;
    width:12rem;
}

.item p {
    margin: 6px 0px;
    font-weight: 500;
       height: 3rem;
}

.item img {
    width: fit-content;
    height: 80px;
    position: relative;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    min-height: 80px;
    object-fit: cover;
}

.item-prices {
    display: flex;
    gap: 20px;
}

.item-price-new {
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}

.item-price-old {
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}

.item:hover {
    transform: scale(1.05);
    transition: 0.6s;
}

.item-instock {
    background-color: #f6efef;
    text-align: center;
    font-weight: 600;
    font-size: small;
    padding: 5px;
    border-radius: 10%;
    color: green;

}

.item-toggle {

    display: flex;
    background-color: #f6efef;
   -ms-flex-align: center;
    border-radius: 8px;
    width: 60%;
    padding-left: 5px;
    color: #116466;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 30px;

}

.item-toggle>button {
   
    border-style: none;
    padding: 2px;
    
}

.item-toggle-quantity {
    
    margin: 5px 0px;
    width:45%;
    text-align: center;
    
}


.item-outofstock {
    color: red;
    background-color: #f5f1f1;
    text-align: center;
    font-weight: 500;
    font-size: small;
    padding: 5px;
    border-radius: 10%;
}

.unit-prices {

    display: flex;
    text-align: center;
    font-size: small;

}

.unit-prices-button {
    background-color: #aad7f8;
    border-color: #aad7f8;
    border-radius: 8px;
    color: white;
    font-weight: 500;
    margin: 0% 0% 5% 2%;
    width:fit-content;
}

.unit-prices-button-active {
    color:black;
    background-color: #4cb2f6;
    border-radius: 8px;
    border-color: #4cb2f6;
    font-weight: 600;
    margin: 0% 0% 5% 2%;
   
    width:fit-content
}
