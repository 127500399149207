.productdisplay {
    display: flex;
    margin: 0px 170px;
}

.productdisplay-left {
    display: flex;
    gap: 17px;
}

.productdisplay-img-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.productdisplay-img-list img {
    height: 160px;
}

.productdisplay-main-img {
    background-color: bisque;
    
}
.productdisplay-right {
    margin: 0px 70px;
    display: flex;
    flex-direction: column;
}

.productdisplay-right h1 {
    color: #3d3d3d;
    font-size: 40px;
    font-weight: 700;
}

.productdisplay-right-star {
    display: flex;
    align-items: center;
    margin-top: 13px;
    gap: 5px;
    color: #1c1c1c;
    font-size: 16px;
}

.productdisplay-right-prices {
    display: flex;
    margin: 30px 0px;
    gap: 30px;
    font-size: 24px;
    font-weight: 700;
}

.productdisplay-right-price-old {
    color: #818181;
    text-decoration: line-through;
}

.productdisplay-right-price-new {
    color: #ff4141;
}

.productdisplay-right-button {
    padding: 20px 40px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background: #ff4141;
    margin-bottom: 40px;
    border: none;
    outline: none;
    cursor: pointer;

}

.productdisplay-unit-prices {

    display: flex;
    text-align: center;
    font-size: small;

}

.unit-prices-button {
    background-color:  #d4d2d2;
    border-color:  #b5b5b5;
    border-radius: 8px;
    color: white;
    font-weight: 500;
    margin: 0% 0% 5% 2%;
    width:fit-content;
}

.unit-prices-button-active {
    background-color: #a1a0a0;
    border-radius: 8px;
    border-color:  #b5b5b5;
    color:black;
    font-weight: 600;
    margin: 0% 0% 5% 2%;
   
    width:fit-content
}

.item-outofstock {
    color: red;
    background-color: #f5f1f1;
    text-align: center;
    font-weight: 500;
    font-size: small;
    padding: 5px;
    border-radius: 10%;
}

.item-instock {
    background-color: #f6efef;
    text-align: center;
    font-weight: 600;
    font-size: small;
    padding: 5px;
    border-radius: 10%;
    color: green;

}

.productdisplay-stock{
    margin-top: 8%;
    padding-left: 2%;
}