
.footer-logo {
    display: flex;
    align-items: center;
    gap: 20px;
}

.footer-logo p {
    color: #383838;
    font-size: 50px;
    font-weight: 700;
}

.footer-links {
    display: flex;
    list-style: none;
    gap: 50px;
    color: white;
    font-size: 20px;
}
