.poojacategory-banner {
    display: block;
    margin: 0.5% auto;
    width: 82%;
    height: 200px;
}

.poojacategory-indexSort {
    display: flex;
    margin: 0px 170px;
    justify-content: space-between;
    align-items: center;
}

.poojacategory-sort {
    padding: 10px 20px;
    border-radius: 40px;
    border: 1px solid #888;
}

.poojacategory-sort img {
    width: 30px;
}

.poojacategory-indexSort p span {
    font-weight: 600
}

.poojacategory-products {
    margin: 20px 170px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 80px;
}

.poojacategory-loadmore {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 150px auto;
    width: 233px;
    height: 69px;
    border-radius: 75px;
    background: #ededed;
    color: #787878;
    font-size: 18px;
    font-weight: 500;
}